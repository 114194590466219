<template>
  <v-dialog
    v-model="dlg.visible"
    width="400"
  >
    <v-card
      class="white"
      flat
    >
      <v-card-title>
        <span>{{ dlg.title }}</span>
        <v-spacer />
        <v-btn
          icon
          @click="onClose"
        >
          <v-icon>
            {{ btnClose.icon }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card
        class="blue-grey lighten-5 pa-8"
        flat
      >
        <div class="co-flex-col co-justify-center co-items-center co-w-full co-h-full">
          <vue-qr
            v-if="myQrcode.text"
            :text="myQrcode.text"
            :size="myQrcode.size"
            :logo-src="myQrcode.logo"
            :logo-scale="myQrcode.logoScale"
          />
          <span class="mt-4">使用微信扫一扫将我的主页分享朋友圈</span>
        </div>
      </v-card>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    components: {
      VueQr: () => import('vue-qr')
    },
    props: {
      userInfo: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        btnClose: {
          icon: 'mdi-close'
        },
        dlg: {
          title: '我的主页二维码',
          visible: false
        },
        myQrcode: {
          visibled: false,
          size: 240,
          text: '',
          logo: require('@/assets/logo.png'),
          logoScale: 0.2
        }
      }
    },
    watch: {
      userInfo: {
        handler (val) {
          this.configMyQrcode(val)
        },
        immediate: true
      }
    },
    methods: {
      configMyQrcode (val) {
        let url = 'http://www.zuimeideguang.com/user/homepage'
        if (val.userId) {
          url = `${url}?userId=${val.userId}`
          this.myQrcode.text = url
        }
      },
      show () {
        this.dlg.visible = true
      },
      onClose () {
        this.dlg.visible = false
      },
    }
  }
</script>
